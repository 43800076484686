
.react-player {
  height: 100%;
  width: 100%;
}


.container {
  height: 100%;
  width: 100%;
}

.player-wrapper {
  height: calc(100%-20px);
  width: 100%;
  margin-bottom: 20px;
}

.input-wrapper {
  height: 20px;
  width: 100%;
}

